<template>
  <div>
    <b-card no-body class="mb-0" v-if="permissions">
      <b-card-body class="text-center p-0" style="height: 100vh">
        <iframe
          v-if="permissions.views.includes('oppertunity_feed')"
          src="https://view.monday.com/1564666504-cfdcb64a4a69d28b474d22772fb9b416?r=use1"
          width="100%"
          height="100%"
          style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.25)"
        ></iframe>
        <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%; width: 100%;">
          Kindly upgrade to get access to this feature
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from "bootstrap-vue";
export default {
  name: "OppertunityFeed",
  components: {
    BCard,
    BCardBody,
  },

  data() {
    return {
      permissions: null,
    };
  },

  async mounted() {
    try {
      await this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        this.permissions = await this.$store.dispatch("get_permissions");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
